<template>
  <div class="div_dialog">
    <el-dialog :visible.sync="show" :close-on-click-modal="false" :close-on-press-escape="false" top="0">
      <slot name="header"></slot>
      <div class="dialog_body">
        <slot name="body"></slot>
        <p>{{ text }}</p>
        <div class="btn_box">
          <slot name="btn"></slot>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'vDialog',
  props: {
    show: Boolean,
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/vDialog.scss';
</style>
